import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import Container from '../primitives/grid/container';
import { WPImage } from '../primitives/image/gatsby-image-svg-fallback';
import { Drawer, DrawerContent } from '../primitives/drawer';
import DrawerInner from '../primitives/drawer/drawer-inner';
import FadeTransition from '../animations/transitions/fade-transition';
import FormProvider from '../primitives/form-provider';
import Input from '../primitives/input';
import TextArea from '../primitives/textarea';
import ColSet from '../primitives/grid/col-set';
import RowSet from '../primitives/grid/row-set';
import Button from '../primitives/button';
import FormAlert from '../primitives/form-alert';
import ArrowRight from '../../images/arrow-right.svg';

const ContactOverlay = (): JSX.Element => {
    const [formSuccess, setSuccess] = React.useState<boolean|undefined>(undefined);
    const [loading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    const {
        wp: {
            acfOptionsOptions: {
                siteOptions: {
                    address,
                    email,
                    linkedin,
                    phoneNumber,
                    twitter,
                    thankyouBackgroundImage,
                    contactFormBackgroundImage,
                },
            },
        },
    } = useStaticQuery(graphql`
        {
            wp {
                acfOptionsOptions {
                    siteOptions {
                        address
                        email
                        linkedin
                        phoneNumber
                        twitter
                        thankYouBackgroundImage {
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(quality: 100)
                                }
                            }
                        }
                        contactFormBackgroundImage {
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(quality: 100)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const handleSubmit = (e: any): void => {
        setIsLoading(true);
        fetch('/api/contact', {
            method: 'POST',
            body: JSON.stringify(e),
            headers: {
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
            },
        }).then((res) => {
            setIsLoading(false);
            return res.json();
        }).then(() => {
            setSuccess(true);
            return true;
        }).catch(() => {
            setIsLoading(false);
            setSuccess(true);
            return false;
        });
    };

    return (
        (
            <Container css={{ flexGrow: 1, paddingBottom: '42px', backgroundColor: 'var(--color-shades-charcoal)' }}>
                <div css={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
                >
                    <WPImage
                        css={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            transition: '0.5s',
                            opacity: formSuccess ? 0 : 1,
                        }}
                        {...contactFormBackgroundImage}
                    />
                    <WPImage
                        css={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            transition: '0.5s',
                            opacity: formSuccess ? 1 : 0,
                        }}
                        {...thankyouBackgroundImage}
                    />
                </div>
                <Drawer
                    open={open}
                    onOpenChange={() => {
                        setOpen(false);
                        setTimeout(() => {
                            window.history.go(-1);
                        }, 300);
                    }}
                >
                    <DrawerContent placement={{ dt: 'left', mb: 'bottom' }} size="xl">
                        <DrawerInner title={formSuccess ? (
                            <>
                                Your enquiry
                                <br />
                                has been submitted.
                            </>
                        ) : (
                            <>
                                Contact
                                <br />
                                proUrban
                            </>
                        )}
                        >
                            <FadeTransition
                                shouldChange={(!!formSuccess).toString()}
                                css={{
                                    paddingRight: '36px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    paddingBottom: '40px',
                                    '@media (max-width: 767px)': {
                                        paddingRight: '20px',
                                    },
                                }}
                            >
                                {!formSuccess ? (
                                    <div css={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                        <FormProvider onSubmit={handleSubmit}>
                                            <div css={{
                                                paddingBottom: '42px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                            }}
                                            >
                                                Got a project? Tell us more.
                                            </div>
                                            <RowSet breakpoints={{ dt: { between: 24 } }}>
                                                <ColSet breakpoints={{ dt: { between: 24 } }}>
                                                    <Input name="name" placeholder="Full Name" />
                                                    <Input name="phone" placeholder="Contact Number" required />
                                                </ColSet>
                                                <ColSet breakpoints={{ dt: { between: 24 } }}>
                                                    <Input name="company" placeholder="Company/Organisation" required />
                                                    <Input name="email" type="email" placeholder="Contact Email" required />
                                                </ColSet>
                                                <div css={{ paddingTop: '14px' }}>
                                                    <TextArea name="message" placeholder="Your Message" required />
                                                </div>
                                            </RowSet>
                                            <div css={{
                                                fontSize: '14px', paddingTop: '24px', lineHeight: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                                            }}
                                            >
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label
                                                    htmlFor="send-form"
                                                    css={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    Send Enquiry
                                                </label>
                                                <Button
                                                    id="send-form"
                                                    type="submit"
                                                    loading={loading}
                                                    css={{
                                                        height: '40px',
                                                        marginRight: '-36px',
                                                        width: '56px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderColor: 'var(--color-shades-black)',
                                                        paddingTop: '12px',
                                                        paddingLeft: '12px',
                                                        paddingBottom: '12px',
                                                        paddingRight: '29px',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'var(--color-primary-pine)',
                                                        borderWidth: '1px',
                                                        borderRight: 'none',
                                                        transition: '0.3s',
                                                        '& svg': {
                                                            transition: '0.3s',
                                                        },
                                                        '&:hover': {
                                                            borderColor: 'var(--color-shades-white)',
                                                            '& svg': {
                                                                transform: 'translateX(8px)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <ArrowRight />
                                                </Button>
                                            </div>
                                            {formSuccess === false && (
                                                <FormAlert>There was an error submitting your enquiry.</FormAlert>
                                            )}
                                        </FormProvider>
                                        <div css={{
                                            display: 'flex',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                        >
                                            <ColSet breakpoints={{ dt: { between: 36 } }}>
                                                <div css={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                                    <a href={`tel:${phoneNumber as string}`}>{phoneNumber}</a>
                                                    <a href={`mailto:${email as string}`}>{email}</a>
                                                </div>
                                                <div css={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                                    <span dangerouslySetInnerHTML={{ __html: address }} />
                                                </div>
                                                <div css={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
                                                    {linkedin && <a href={linkedin}>linkedin</a>}
                                                    {twitter && <a href={twitter}>Twitter</a>}
                                                </div>
                                            </ColSet>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div css={{
                                            paddingBottom: '42px',
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                        }}
                                        >
                                            Thank you for contacting proUrban.
                                            <br />
                                            We endeavour to respond to all business requests as soon as possible.
                                        </div>
                                        <div css={{
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                        >
                                            <div css={{
                                                fontSize: '14px', paddingTop: '24px', lineHeight: '20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                                            }}
                                            >

                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label
                                                    htmlFor="go-back"
                                                    css={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    Return Home
                                                </label>
                                                <Button
                                                    id="go-back"
                                                    type="button"
                                                    onClick={() => {
                                                        setOpen(false);
                                                        setTimeout(() => {
                                                            navigate('/');
                                                        }, 300);
                                                    }}
                                                    css={{
                                                        height: '40px',
                                                        marginRight: '-36px',
                                                        width: '56px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderColor: 'var(--color-shades-black)',
                                                        paddingTop: '12px',
                                                        paddingLeft: '12px',
                                                        paddingBottom: '12px',
                                                        paddingRight: '29px',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'var(--color-primary-pine)',
                                                        borderWidth: '1px',
                                                        borderRight: 'none',
                                                        transition: '0.3s',
                                                        '& svg': {
                                                            transition: '0.3s',
                                                        },
                                                        '&:hover': {
                                                            borderColor: 'var(--color-shades-white)',
                                                            '& svg': {
                                                                transform: 'translateX(8px)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <ArrowRight />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </FadeTransition>
                        </DrawerInner>
                    </DrawerContent>
                </Drawer>
            </Container>
        )
    );
};

export default ContactOverlay;
